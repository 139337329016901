@import '../../styles/variables';
@import '../../styles/mixins';

.services {
  margin-top: 9rem;
  color: $black;

  &__header {
    margin: 10rem 0 3rem 0;

    @include breakpoint-down(medium) {
      margin: 3rem 0;
    }
  }
  
  &__colorGradient {
    background: linear-gradient(hsl(229, 18%, 77%), $white);
    height: 5vh;
    width: 100%;
  }
}
