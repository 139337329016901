@import '/src/styles/variables';
@import '/src/styles/_mixins.scss';


.nsoc {
  color: $black;
  line-height: 1.5;
  font-size: $font-xl;

  &__img {
    &-secondary {
      // margin: 5rem ;
      width: 55vw;
      border-radius: 15px;
      // overflow: hidden;

      @include breakpoint-down(large) {
        width: 65vw;
      }
      @include breakpoint-down(medium) {
        margin: 2rem;
        width: 100%;
      }
      @include breakpoint-down(small) {
        margin: 0;
      }
    }

    &-row {
      @include breakpoint-down(xlarge) {
        height: 50vh; 
        overflow: hidden;
      }

      @include breakpoint-down(large) {
        height: 70vh;
      }

      @include breakpoint-down(medium) {
        height: 50vh;
      }

      @include breakpoint-down(small) {
        height: 100%;
      }

      &__img {
        height: 100%;
        @include breakpoint-down(large) {
          height: 100%;
        }
        @include breakpoint-down(medium) {
          height: 50vh;
        }
      }
    }

    // &-absolute {
    //   position: absolute;
    //   top: 125vh;
    //   width: 70vw;
    //   height: 65vh;
    //   left: -10vw;
    //   height: 60vh;
    //   border-radius: 15px;
      
    // }
  }

  &__header {
    color: $light-gray;
    position: absolute;
    width: 910px;
    top: 30vh;
    left: 10vw;
    line-height: 1.5;
    font-size: 6rem;
    font-weight: $font-med;
    margin-left: 2rem;
  
    @include breakpoint-down(small) {
      font-weight: $font-thick;
    }
  
    @include breakpoint-down(medium) {
      font-size: 2rem;
      width: auto;
      top: 140px;
    }
  }

  &__aux {
    margin: 2rem;
    padding: 5rem;

    @include breakpoint-down(medium) {
      margin: 0;
      padding: 0;
    }

  }

  &__p {
    text-align: justify;
    margin: 2rem 5rem;
    @include breakpoint-down(xlarge) {
      margin: 2rem 0;
    }
    @include breakpoint-down(medium) {
      font-size: $font-med;
    }
  }

  &__slimheader {
    font-weight: $font-thick;
  }

  &__bigimg {
    width: 100vw;
    
  }

  &__pink {
    color: #F017B5;
  }
}
