@import '../../styles//variables';
@import '../../styles/_mixins.scss';

.privacy {
  font-size: $font-ssm;
  color: $black;
  margin: auto;
  padding: 10rem 0rem;

  @include breakpoint-down(medium) {
    padding: 3rem 0rem;
  }

  &__box {
    width: 70vw;
    padding: 2rem;
    margin: 5rem auto;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;

    @include breakpoint-down(medium) {
      font-size: $font-ssm;
      width: 92vw;
      margin: 4rem auto;
      padding: 0.5rem;
    }
  }
}
