@import '../../styles/variables';
@import '../../styles/mixins';

:root {
  --typewriterSpeed: 6s;
  --typewriterCharacters: 40;
}

.typewriter {
  position: relative;
  width: 100vw;

  &-container {
    float: center;
    position: absolute;
    left: 10%;
    top: 50%;
    z-index: 1;
    font-family: 'Source Sans Pro', sans-serif;

    @include breakpoint-down (large) {
      left: 8%;
    }
    @include breakpoint-down (medium) {
      top: 40%;
      left: 5%;
    }
  }

  &__link {
    text-decoration: none;
    color: $white;
    &:hover {
      color: $blue-logo;
    }
  }

  &__subtitle {
    color: $white;
    font-size: 2rem;
    font-weight: $font-thick;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 4s ease calc(var(--typewriterSpeed) + 1s) infinite;
  
    @include breakpoint-down(medium) {
      font-size: $font-med;
    }
    @include breakpoint-down(small) {
      font-size: $font-sm;
    }
  }
}

.header-animated {
  font-family: 'Source Code Pro', monospace;
  font-size: clamp(1rem, 3vw + 1rem, 5.2rem);
  font-weight: $font-thick;
  position: relative;
  width: max-content;
  margin: auto;
  color: $white;

  @include breakpoint-down (xlarge) {
    font-size: clamp(1rem, 3vw + 1rem, 4rem);
  }
  @include breakpoint-down (large) {
    font-size: clamp(1rem, 3vw + 1rem, 3rem);
  }
  @include breakpoint-down (medium) {
    font-size: clamp(1rem, 3vw + 1rem, 3rem)
  }
  @include breakpoint-down (small) {
    font-size: clamp(1rem, 3vw + 1rem, 1.8rem);
  }
  @include breakpoint-down (mobile) {
    font-size: $font-sm;
  }
}

.header-animated::before,
.header-animated::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.header-animated::before {
  background: rgb(0, 0, 0, 0.5);
  animation: typewriter var(--typewriterSpeed)
    steps(var(--typewriterCharacters)) 1s infinite;
}

.header-animated::after {
  width: 0.125em;
  background: $white;
  animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s infinite,
    blink 300ms steps(var(--typewriterCharacters)) infinite;
}

@keyframes typewriter {
  to {
    left: 100%;
  }
}

@keyframes blink {
  to {
    background: transparent;
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
