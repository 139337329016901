@import '../../styles/variables';

.eventsGallery {
  margin-top: 8rem;
  color: $black;

  &__img {
    height: 200px;
    width: 100%;
    transform: scale(1);
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
  
  &__box {
    padding: 0;
    text-decoration: none;
    color: $black;
    &:hover {
      text-decoration: none;
      .eventsGallery__box-p {
        color: $black;
      }
    }

    &-p {
      font-size: $font-ssm;
      padding: 1rem;
    }
  }
}