@import '../../../styles/mixins';

.support {
  margin-top: 10rem;

  &__form {
    width: 700px;
    margin: 3rem 1rem;

    @include breakpoint-down(small) {
      width: 90vw;
    }
  }
}
