@import '../../styles/variables';
@import '../../styles/mixins';

.wave {

  &__header {
    position: absolute;
    width: 60vw;
    top: 30vh;
    left: 20vw;
    line-height: 1.5;
    font-size: 4rem;
    font-weight: $font-big;
    color: $white;
    
    @include breakpoint-down(small) {
      font-size: 3rem;
      left: 10vw;
      width: 80vw;
      top: 6rem;
    }
    @include breakpoint-down(mobile) {
      font-size: 1.6rem;
      left: 5vw;
      width: 90vw;
      top: 5rem;
    }
  }
  
  &__video {
    margin-top: -9rem;
    // @include breakpoint-down(medium) {
    //   margin-top: -5rem;
    // }
  }
}
