@import '../../styles/variables';

.news {
  color: $black;
  margin: 20vh 0;

  &__img {
    height: 200px;
    width: 100%;
  }
}