@import '../../styles/_mixins.scss';
@import '../../styles/variables';

.partners {
color: $black;
  &__writer {
    color: $black;
  }

  &__img {
    height: 112vh;
    width: 100vw;
    object-fit: cover;
    object-position: center -530px;
    filter: drop-shadow(1px 10px 40px rgb(214, 212, 212));
    @include breakpoint-down (xlarge) {
    object-position: center -400px;
    }
    @include breakpoint-down (large) {
      object-position: center -380px;
      height: 92vh;
    }
    @include breakpoint-down (medium) {
      object-position: center -370px;
      margin-top: 5rem;
      height: 100vh;
    }
    @include breakpoint-down (small) {
      object-position: center -200px;
      margin-top: 5rem;
      height: 37vh;
    }
    @include breakpoint-down (mobile) {
      object-position: center -100px;
      margin-top: 5rem;
      height: 27vh;
    }
  }

  &__center {
    font-size: 1.5rem;
    font-weight: $font-thick;
  }
  &__box {
    margin-left: 6.5rem;
    width: 550px;

    @include breakpoint-down(large) {
      margin-left: 0rem;
    }
  }

  &__header {
    position: absolute;
    width: 1300px;
    top: 18vh;
    left: 16vw;
    line-height: 1.5;
    font-size: 4.5rem;
    font-weight: $font-thick;
  
    @include breakpoint-down(small) {
      font-weight: $font-thick;
    }
  
    @include breakpoint-down(medium) {
      font-size: 2rem;
      width: auto;
      top: 140px;
    }
  }

  &__title {
    margin: 10vh 0;
  }

  &__author {
    margin-left: 4rem;
  }

  &__contact {
    margin: 20vh;
    @include breakpoint-down(medium) {
      margin: 5rem 0;
    }

    &-title {
      margin: 7rem 0;

      @include breakpoint-down(medium) {
        margin: 3rem 0;
      }
    }
  }
}

