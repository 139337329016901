@import '../../styles//variables';

.partnerBenefits {
  color: $black;
  font-weight: $font-thick;
  font-size: $font-bg;

  &__arrow {
    color: red;
    transition: all 2s ease-in-out;
    animation: movement 2s ease-in-out infinite;
    &:before {
      transition: all 2s ease-in-out;
    }
  }
}

@keyframes movement {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(20px);
  }
}