@import '../../../styles/variables';
@import '../../../styles/mixins';

.caseFiji {
  color: $black;
  line-height: 1.5;
  margin-top: 5rem;
  @include breakpoint-down(medium) {
    line-height: 1.2;
    margin-top: 0rem;
  }

  &__title {
    margin: 5rem;

    @include breakpoint-down(medium) {
      margin: 1rem;
    }
  } 

  &__aux {
    padding: 5rem 12rem;
    @include breakpoint-down(medium) {
      padding: 1rem;
    }
  }

  &__space {
    margin-top: 6rem;
  }
}