@import '../../styles/variables';
@import '../../styles/mixins';

.getTouch {
  &__contact {
    margin-bottom: 15vh;
    &-color {
      color: $blue-logo;
      font-weight: $font-thick;
      @include breakpoint-down(medium) {
        padding: 1rem;
      }
    }
  }
}
