@import '../../styles/mixins';

.animatedCircle {
  margin: 2rem;
  @include breakpoint-down(medium) {
    margin:0 0 5rem 2.5rem;
  }
  @include breakpoint-down(large) {
    margin: 2rem 3.6rem;
  }

  &__each {
    margin: 0 1.4rem;
    @include breakpoint-down(xlarge) {
      margin:0 0 5rem 2.5rem;
    }
    @include breakpoint-down(large) {
      margin: 2rem 1rem;
    }
    @include breakpoint-down(medium) {
      margin: 5rem 0;
    }
    @include breakpoint-down(small) {
      margin: 3rem 0;
    }

    @include breakpoint-down(mobile) {
      margin: 3rem 0;
    }
  }
}