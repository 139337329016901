@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4.scss';

#aux1 {
  display: none;
}

#others:checked ~  #aux1{
  display: block;
}

#aux2 {
  display: none;
}

#others2:checked ~  #aux2{
  display: block;
}