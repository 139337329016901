@import '../../styles/mixins';

.map {
  height: 70vh;
  border-radius: 10px;

  @include breakpoint-down(medium) {
    height: 80vh;
  }
  @include breakpoint-down(small) {
    height: 50vh;
  }
}

.leaflet-container {
  background-color: white;
}