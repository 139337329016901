// Colors
$light-gray: #f1f5fd; 
$blue-jeans: #63a8ea;
$blue-logo: #1f55ba; 
$navy: #203865; 
$black: #000000;
$white: #ffffff;

// Font Sizes
$font-ssm: 0.75rem; // 12 px
$font-sm: 0.875rem; // 14 px
$font-med: 1.125rem; // 18 px
$font-bg: 1.375rem; // 20 px
$font-xl: 1.5rem; // 24px

//Font thickness
$font-slim: 200; //slim
$font-thick: 400; //thick
$font-bold: 800; //bold
$font-big: 1000; //big-heading
