@import '../../styles/mixins';
@import '../../styles/variables';

.contact {
  color: $black;
  
  &__title {
    margin-top: 15rem;
    margin-bottom: 2rem;
  }

  &__box {
    padding: 1rem;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  &__link {
    text-decoration: none;
    color: $black;
  }

  &__card {
    border-radius: 15px;
    text-align: center;
    margin: 2rem;
    padding: 1rem;

    &:hover {
      color: $blue-logo;
      cursor: pointer;
      background-color: $light-gray;
    }

    &-title {
      margin-bottom: 1rem;
    }

    
    @include breakpoint-down(large) {
      width: 60vw;
    }
    
    @include breakpoint-down(small) {
      width: 80vw;
    }
  }
  
  &__font {
    font-size: $font-ssm;

    @include breakpoint-down(medium) {
      padding: 2rem;
    }
  }
}

.blue {
  color: $blue-logo;
}
