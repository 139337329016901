@import '../../../styles/variables';
@import '../../../styles/mixins';

.caseFiji2 {
  color: $black;
  line-height: 1.5;
  margin-top: 5rem;
  @include breakpoint-down(medium) {
    line-height: 1.2;
    margin-top: 0rem;
  }

  &__title {
    position: absolute;
    width: 40vw;
    top:  45vh;
    left: 55vw;
    line-height: 1.5;
    font-size: 4rem;
    font-weight: $font-big;
    color: $white;

    @include breakpoint-down(medium) {
      font-size: 3rem;
      width: 40vw;
      top:  30vh;
      left: 55vw;
    }

    @include breakpoint-down(small) {
      font-size:  2rem;
      width: 40vw;
      top:  25vh;
      left: 55vw;
    }

    @include breakpoint-down(mobile) {
      top: 12vh;
      left: 5vw;
      width: 90vw;
      font-size:  1.5rem;
    }
  }

  &__icon {
    width: 75px;

    @include breakpoint-down(medium) {
      width: 60px;
    }

    @include breakpoint-down(small) {
      width: 50px;
    }
  }

  &__textimg {
    width: 500px;
    @include breakpoint-down(mobile) {
      width: 80vw;
    }
  }

  &__lastimg {
    width: 100%;
  }

  &__space {
    display: flex;
    // justify-content: space-around;
  }

  &__width {
    width: 40vw;
    @include breakpoint-down(large) {
      width: 60vw;
    }

    @include breakpoint-down(medium) {
      width: auto;
    }
  }

  &__right {
    float: right;
  }

  &__left {
    float: left;
  }

  &__margin {
    margin-left: 45%;

    @include breakpoint-down(large) {
      margin-left: 35%;
    }

    @include breakpoint-down(medium) {
      margin-left: 53%;
    }
    @include breakpoint-down(mobile) {
      margin-left: auto;
    }
  }
}