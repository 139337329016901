@import '../../../styles/variables';
@import '../../../styles/mixins';

.general {
  &__title {
    margin-top: 10rem;
  }

  &__form {
    margin: 3rem 1rem;
    width: 700px;

    @include breakpoint-down(medium) {
      width: 90vw;
    }
  }

  &__box {
    color: $black;
  }
}
