@import '/src/styles/variables';
@import '/src/styles/_mixins.scss';

.footer {
  font-size: $font-sm;
  font-weight: $font-thick;
  line-height: 1.5;

  &__link {
    color: $light-gray;

    &:hover {
      color: $white;
      transition: all 300ms ease-in-out;
    }

    &-fontsize {
      font-size: 2em;
    }

    &-margin {
      margin-top: 0.625rem;
    }
  }

  &__primary {
    margin-right: 0;
    background: linear-gradient(60deg, $blue-logo 0%, $blue-jeans 100%);

    &-list {
      list-style: none;
    }
  }

  &__media {
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  &__secondary {
    background: linear-gradient(60deg, $blue-logo 0%, $blue-jeans 100%);
    margin-right: 0;

    &-legal {
      text-align: start;

      @include breakpoint-down(medium) {
        text-align: center;
      }
    }

    &-copyright {
      text-align: right;

      @include breakpoint-down(medium) {
        text-align: center;
      }
    }
  }

}



.container__waves {
  position:relative;
  text-align:center;
  background: linear-gradient(60deg, $blue-logo 0%, $blue-jeans 100%);
  color:white;
  &-waves {
    position:relative;
    width: 100%;
    height:15vh;
    min-height:100px;
    max-height:150px;
  }
}






/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height:40px;
    min-height:40px;
  }
}