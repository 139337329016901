@import '../../styles/variables';
@import '../../styles/mixins';

.hero {
  color: $black;
  width: 100%;
  position: relative;

  @include breakpoint-down(medium) {
    height: 80vh;
  }
  @include breakpoint-down(small) {
    height: 1000px;
  }
  @include breakpoint-down(mobile) {
    height: 160vh;
  }

  &__container {
    width: 47vw;
    height: 50vh;
    position: absolute;
    top: 20vh;
    left: 27vw;
    transform: translateY(-50%, -50%);

    @include breakpoint-down(medium) {
      width: 90vw;
      left: 5vw;
      height: 60vh;
    }
  }

  &__indicator{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50px;
    display: none;

    &-btn{
      display: inline-block;
      height: 15px;
      width: 15px;
      margin: 4px;
      border-radius: 15px;
      background: hsla(205, 95%, 45%, 0.4);
      cursor: pointer;
      transition: 0.5s;
    }

    &-active {
      width: 45px;
    }
  }

  &__title {
    margin-top: 8rem;
    margin-bottom: 85vh;
  }

  &__slide-row {
    display: flex;
    width:60vw;
    transition: 0.45s;
    background: white;
    @include breakpoint-down(large) {
      width:70vw;
    }
    @include breakpoint-down(medium) {
      width:90vw;
    }
  }

  &__slide-col {
    width: 70vw;
    height: 50vh;
    position: relative;

    @include breakpoint-down(large) {
      width: 100vh;
    }
    @include breakpoint-down(medium) {
      width: 115vh;
    }
    @include breakpoint-down(small) {
      width: 100vh;
      
    }
  }

  &__testimonial {
    width: 850px;
    height: 70vh;

    @include breakpoint-down(large) {
      height: 60vh;
      width: 10vh;
    }
  }

  &__user {
    position: absolute;
    top: 0;
    right: 25vw;
    @include breakpoint-down(xlarge) {
      right: 13vw;
    }
    @include breakpoint-down(large) {
      right: 0;
    }
    @include breakpoint-down(medium) {
      right: 0;
    }
    @include breakpoint-down(small) {
      right: 25vw;
    }
    @include breakpoint-down(mobile) {
      right: 0vw;
    }

    &-img {
      height: 500px;
      width: 400px;
      border-radius: 20px;
      object-fit: cover;
      object-position: center;
      @include breakpoint-down(mobile) {
        width: 90vw;
      }
    }

    &-text {
      background-color: hsla(205, 95%, 95%, 0.7);
      width: 520px;
      height: 390px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 20px;
      color: $blue-jeans;
      padding: 45px;
      box-sizing: border-box;
      z-index: 2;

      @include breakpoint-down(small) {
        top: 52vh;
        left: 10vw;
        line-height: 1.3;
        width: 70vw;
        height: 380px;
      }
      @include breakpoint-down(mobile) {
        padding: 1rem;
        width: 90vw;
        height: 470px;
        top: 110vh;
        left: 0;
      }
      
      &-p {
        color: $black;
        font-size: $font-med;
        line-height: 24px;
      }

      &-h {
        margin: 35px 0 5px;
        color: $blue-logo;
      }
    }
  }
}

