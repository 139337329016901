.blogDemo {
  .container {
    padding: 0 15px;
  }

  .center-header__title {
    font-size: 2.5rem;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .blogDemo__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 20px;

    
    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

   
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .blogDemo__box {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    .blogDemo__imgbox {
      width: 100%;
      height: 250px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease; 
      }

      &:hover img {
        transform: scale(1.1); 
      }
    }

    .blogDemo__details {
      padding: 15px;

      .blogDemo__tags {
        font-size: 14px;
        color: #007bff;
        margin-bottom: 10px;
      }

      .blogDemo__title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: black; 
      }

      .blogDemo__author {
        font-size: 14px;
        color: #555;
        margin-bottom: 10px;
      }

      .blogDemo__introduction {
        font-size: 14px;
        color: #333;
        margin-bottom: 20px;
      }

      .blogDemo__author a {
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .text-end {
        text-align: right;

        a {
          font-size: 14px;
          color: #007bff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .add-blog-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  
  .testimonial,
  .getInTouch {
    margin-top: 20px;
  }

  
  @media (max-width: 767px) {
    .center-header__title {
      font-size: 2rem;
    }

    .blogDemo__list {
      grid-template-columns: 1fr;
    }

    .blogDemo__box {
      .blogDemo__imgbox {
        height: 200px;  
      }

      .blogDemo__details {
        padding: 10px;

        .blogDemo__title {
          font-size: 1.4rem;
        }

        .blogDemo__author {
          font-size: 13px;
        }

        .blogDemo__introduction {
          font-size: 13px;
        }

        .text-end a {
          font-size: 13px;
        }
      }
    }

    .add-blog-btn {
      width: 100%;
      padding: 12px 20px;
      font-size: 16px;
    }
  }

  
  @media (max-width: 480px) {
    .center-header__title {
      font-size: 1.6rem;
    }

    .blogDemo__box {
      .blogDemo__imgbox {
        height: 180px; 
      }

      .blogDemo__details {
        .blogDemo__title {
          font-size: 1.2rem;
        }

        .blogDemo__author {
          font-size: 12px;
        }

        .blogDemo__introduction {
          font-size: 12px;
        }
      }

      .add-blog-btn {
        font-size: 14px;
        padding: 10px;
      }
    }
  }
}
