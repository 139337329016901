@import '../../styles/variables';
@import '../../styles/globals';

.notFound {
  &__title {
    margin-top: 9rem;
    margin-bottom: 3rem;

    @include breakpoint-down(medium) {
      margin-bottom: 1rem;
    }
  }

  &__sentence {
    text-align: center;
    color: $black;
    margin-left: auto;
    margin-right: auto;
    width: 40%;

    @include breakpoint-down(medium) {
      width: 100%;
    }
  }

  &__container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 100%;
  
    @include breakpoint-down(medium) {
      width: 93vw;
    }
  }
}

.flex-item {
  text-align: center;
}
