@import '../../styles/variables';
@import '../../styles/mixins';

:root {
  --level-one: translateZ(3rem);
  --level-two: translateZ(6rem);
  --level-three: translateZ(9rem);
}

.card {
  &__bodyup {
    height: 35vh;
    display: grid;
    place-items: center;
    
    @include breakpoint-down(xlarge) {
      height: 45vh;
    } 
    @include breakpoint-down(large) {
      height: 50vh;
    } 
    @include breakpoint-down(medium) {
      height: 60vh;
    } 
    @include breakpoint-down(small) {
      height: 430px;
    } 
    @include breakpoint-down(mobile) {
      height: 430px;
    } 
  }

  &__container {
    width: 750px;

    @include breakpoint-down(xlarge) {
      width: 550px;
    }
    @include breakpoint-down(large) {
      width: 470px;
    }
    @include breakpoint-down(medium) {
      width: 420px;
    }
    @include breakpoint-down(small) {
      width: 640px;
    }
    @include breakpoint-down(mobile) {
      width: 90vw;
    }
  }

  &__content {
    text-align: center;
    position: relative;
    padding: 11em 5em;
    transition: transform 1.5s;
    transform-style: preserve-3d;

    @include breakpoint-down(mobile) {
      padding: 12em 5em;
    }
  }

  &__container:hover &__content {
    transform: rotateY(.5turn);
  }

  &__front,
  &__back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5em 3em;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    display: grid;
    align-content: center;
    @include breakpoint-down(large) {
      padding: 5em 1em;
    }
    @include breakpoint-down(small) {
      padding: 4em 1em;
      margin: 1rem 0;
    }
  }

  &__fz-ssm {
    font-size: $font-ssm;
  }

  &__front {
    background-size: cover;
    background-blend-mode: overlay;
    color: $white;
    border-radius: 15px;
  }
  
  // Images 
  &__front-background1 {
    background-image: url(https://i.imgur.com/nc1zEeK.jpg);
  }
  &__front-background2 {
    background-image: url(https://i.imgur.com/NT1vi1E.jpg);
  }
  &__front-background3 {
    background-image: url(https://i.imgur.com/8hwiiHu.jpg);
  }
  &__front-background4 {
    background-image: url(https://i.imgur.com/m3LfdXD.jpg);
  }
  &__front-background5 {
    background-image: url(https://i.imgur.com/nY6lGAY.jpg);
  }
  &__front-background6 {
    background-image: url(https://i.imgur.com/bXJaItf.jpg);
  }
  &__front-background7 {
    background-image: url(https://i.imgur.com/2EjcJ2N.jpg);
  }
  &__front-background8 {
    background-image: url(https://i.imgur.com/0Zf9Vzf.jpg);
  }
  &__front-background9 {
    background-image: url(https://i.imgur.com/TmMIKJl.jpg);
  }

  &__front::before {
    content: '';
    position: absolute;
    --spacer: 1em;
    top: var(--spacer);
    bottom: var(--spacer);
    left: var(--spacer);
    padding-bottom: 20px;
    right: var(--spacer);
    transform: var(--level-one);
  }

  &__title {
    font-size: 2.7rem;
    transform: var(--level-three);
  }

  &__body {
    transform: var(--level-two);
    font-weight: var(fw-normal);
    font-size: 1rem;
    color: $black;
  }

  &__back {
    transform: rotateY(.5turn);
    color: $white;
    background: $light-gray;
    border-radius: 15px;
  }
}