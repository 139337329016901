@import '../../styles/mixins';

div#background {
  height: 10vw;
  width: 100%;
  background-image: url(http://garchitecture.ca/php/images/headers/wood.jpg);
  padding-top: 20px;

  @include breakpoint-down(medium) {
    height: 30vh;
  }
  @include breakpoint-down(small) {
    height: 20vh;
  }
  @include breakpoint-down(mobile) {
    height: 50vh;
  }
}

div#gallery {
  color: black;
  width: 800px;
  margin: auto;
  @include breakpoint-down(medium) {
    width: 100%;
    margin-left: 25vw;
  }
  @include breakpoint-down(small) {
    margin-left: 5vw;
  }
}


#background img {
  height: 150px;
  margin: 0px;
}

#background figure {
  float: left;
  position: relative;
  background-color: white;
  text-align: center;
  font-size: 15px;
  padding: 10px;
  margin: 10px;
  box-shadow: 1px 2px 3px black;
}

figure.pic1 {
  -webkit-transform : rotate(-10deg);
  transform : rotate(-10deg);
  z-index: 1;
}

figure.pic2 {
  -webkit-transform : rotate(15deg);
  transform : rotate(15deg);
  z-index: 2;
}

figure.pic3 {
  -webkit-transform : rotate(-25deg);
  transform : rotate(-25deg);
  z-index: 1;
}

figure.pic4 {
  -webkit-transform : rotate(5deg);
  transform : rotate(5deg);
  z-index:1;

  @include breakpoint-down(medium) {
    display: none;
  }
}

figure.pic5 {
  -webkit-transform : rotate(5deg);
  transform : rotate(5deg);
  z-index:1;
}

figure.pic6 {
  -webkit-transform : rotate(-8deg);
  transform : rotate(-8deg);
  z-index:1;
}

figure.pic7 {
  -webkit-transform : rotate(2deg);
  transform : rotate(2deg);
  z-index:1;
}

figure.pic8 {
  -webkit-transform : rotate(-13deg);
  transform : rotate(-13deg);
  z-index:1;
}

figure.pic9 {
  -webkit-transform : rotate(-7deg);
  transform : rotate(-7deg);
  z-index:1;
}

figure.pic10 {
  -webkit-transform : rotate(2deg);
  transform : rotate(2deg);
  z-index:1;
}

figure.pic11 {
 -webkit-transform : rotate(-3deg);
 transform : rotate(-3deg);
  z-index:1;
}

figure.pic12 {
  -webkit-transform : rotate(10deg);
  transform: rotare(10deg);
  z-index: 1;
}

#background figure:hover {
  box-shadow: 5px 10px 100px black;
  -webkit-transform: scale(1.1,1.1);
  transform: scale(1.1,1.1);
  z-index: 20;
}
