@import '../../styles/_variables.scss';

.carouselIndu {
  height: 280px;
  // margin: 2rem;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;

  &__track {
    color: $white;
    display: flex;
    width: calc(300px * 26);
  }
  overflow: hidden;
}
.carousel__container {
  width: 100%;
  white-space: nowrap;
  margin: 70px 0px;
  padding-bottom: 10px;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 15));
  }
}
.carousel__item {
  background-color: $light-gray;
  opacity: 0.6;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  object-fit: cover;
  object-position: right;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  align-items: center;
  transition: 450ms all;
  padding: 1rem;

  &-1 {
    background-image: url(https://i.imgur.com/ZpvRUF9.jpg);
  }
  &-2 {
    background-image: url(https://i.imgur.com/kaZ1miU.jpg);
  }
  &-3 {
    background-image: url(https://i.imgur.com/vW63Non.jpg);
  }
  &-4 {
    background-image: url(https://i.imgur.com/96AIFvU.jpg);
  }
  &-5 {
    background-image: url(https://i.imgur.com/C5saJiG.jpg);
  }
  &-6 {
    background-image: url(https://i.imgur.com/kaY5tJR.jpg);
  }
  &-7 {
    background-image: url(https://i.imgur.com/k9m2WA7.jpg);
  }
  &-8 {
    background-image: url(https://i.imgur.com/j4n0evW.jpg);
  }
}

.carousel__item h3 {
  font-weight: $font-thick;
  font-size: $font-med;
}
.carousel__item:hover ~ .carousel__item {
  transform: translate3d(50px, 0, 0);
}
.carousel__container:hover .carousel__item {
  opacity: 0.2;
}
.carousel__container:hover .carousel__item:hover {
  transform: scale(1.1);
  opacity: 1;
}

