@import '../../styles/variables';
@import '../../styles/mixins';

.CoverageFormat {
  color: black;

  &__title {
    position: absolute;
    width: 50vw;
    top: 15vh;
    left: 25vw;
    line-height: 1.5;
    font-size: 3rem;
    font-weight: $font-med;
    color: $white;

    @include breakpoint-down(medium) {
      font-size: 3rem;
      width: 40vw;
      top: 30vh;
      left: 55vw;
    }

    @include breakpoint-down(small) {
      font-size: 2rem;
      width: 40vw;
      top: 25vh;
      left: 55vw;
    }

    @include breakpoint-down(mobile) {
      top: 12vh;
      left: 5vw;
      width: 90vw;
      font-size: 1.5rem;
    }
  }

  &__tag {
    font-size: 1.5rem;
  }
  /* Container for flexboxes */
  section {
    display: -webkit-flex;
    display: flex;
  }

  /* Style the navigation menu */
  nav {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  /* Style the list inside the menu */
  nav ul {
    //list-style-type: none;
    padding: 0;
  }

  ul li {
    margin: 1rem;
  }

  ol li {
    margin: 1rem;
  }

  /* Style the content */
  article {
    color: black;
    -webkit-flex: 3;
    -ms-flex: 3;
    flex: 3;
    padding: 10px;
  }

  &__saturation {
    filter: drop-shadow(1px 10px 40px rgb(0, 0, 0)) saturate(110%);
  }

  &__margins {
    margin: 3rem;

    @include breakpoint-down(small) {
      margin: 1rem 0 1rem 0;
    }
  }

  &__flag {
    width: 150px;
    border: 1px solid black;
  }

  /* Responsive layout - makes the menu and the content (inside the section) sit on top of each other instead of next to each other */
  @include breakpoint-down(small) {
    section {
      -webkit-flex-direction: column;
      flex-direction: column;
    }
  }
}
