@media (max-width: 768px) {
  .form-group {
    margin-bottom: 12px;
  }

  input,
  .ql-container {
    font-size: 14px;
    padding: 10px;
  }

  button {
    padding: 14px;
    font-size: 16px;
  }

  .ql-container {
    height: auto;
  }

  h2 {
    font-size: 18px;
  }

  label {
    font-size: 14px;
  }
}
