@import '../../styles/variables';
@import '../../styles/mixins';

.aboutUs {
  margin-top: 6rem;

  @include breakpoint-down(medium) {
    margin-top: 0;
  }
  
  &__box {
    color: $black;
    padding: 0 10rem;
    margin: 3rem 5rem;

    @include breakpoint-down(medium) {
      margin: 1rem;
      padding: 1rem;
      line-height: 1.3;
      font-size: $font-sm;
    }
  }
}