@import '../../../styles/mixins';
@import '../../../styles/variables';

.bb {
  color: $black;
  line-height: 1.5;

  &__bigImg {
    width: 100vw;
    filter: drop-shadow(1px 10px 40px rgb(0, 0, 0)) saturate(160%);
  }

  &__gradient {
    height: 5vh;
    width: 100vw;
  }

  &__title {
    color: $white;
    position: absolute;
    width: 70vw;
    top: 20vh;
    left: 10vw;
    line-height: 1.5;
    font-size: 8rem;
    font-weight: $font-thick;
    margin-left: 2rem;

    @include breakpoint-down(small) {
      font-weight: 300;
    }

    @include breakpoint-down(medium) {
      font-size: 2rem;
      width: auto;
    }
    @include breakpoint-down(large) {
      top: 20vh;
    }
  }

  &__subtitle {
    font-size: $font-xl;
    margin: 5rem 0;
    font-weight: 300;
  }

  &__q {
    margin: 3rem 0;
  }
  &__space {
    margin: 7rem 0;
  }
}