@import '../../styles/variables';
@import '../../styles/mixins';

.blogPosts {
  margin: 7rem 0;

  &__box {
    margin: 3rem 0;
    color: $black;
    width: 100%;
    height: 220px;
    border-bottom: 1px solid $blue-jeans;
    border-radius: 20px;
    transition: all .5s ease-in-out;

    @include breakpoint-down(small) {
      height: 260px;
      padding: 0 1rem;
      font-size: $font-ssm;
    }
    @include breakpoint-down(mobile) {
      height: 530px;
      padding: 0 1rem;
      font-size: $font-ssm;
    }
    
    &:hover {
      background: linear-gradient(white, $light-gray );
    }
  }
  &__imgbox {
    height: 219px;
    width: 319px;
    float: left;
    overflow: hidden;
    border-radius: 20px 0 0 20px;
    @include breakpoint-down(mobile) {
      height: 269px;
      border-radius: 20px;
    }
  }
  
  
  &__img {
    border-radius: 20px 0 0 20px;
    margin-top: 2rem;
    width: 100%;
    overflow: hidden;
    transform-origin: 50% 50%;
    transition: all .5s ease-in-out;

    &:hover {
      transform: scale(1.3);
    }

    @include breakpoint-down(mobile) {
      border-radius: 20px;
    }
  }

  &__title {
    @include breakpoint-down(medium) {
      font-size: 1.5rem;
    }
    @include breakpoint-down(small) {
      font-size: 1.2rem;
    } 
    @include breakpoint-down(mobile) {
      font-size: 1rem;
    }
  }

  &__tags {
    font-size: $font-sm;

    @include breakpoint-down(mobile) {
      font-size: $font-ssm;
    }
  }


}