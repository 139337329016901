@import '../../styles/mixins';
@import '../../styles/variables';

.countryDetail {
  color: $black;
  line-height: 1.5;

  &__spacing {
    margin: 0 0 0 2rem;
    
    @include breakpoint-down(medium) {
      margin: 2rem 0 4rem 3rem;
    }
  }
  &__padding {
    padding: 3rem;

    @include breakpoint-down(medium) {
      padding: 1rem 0;
    }
  }
  &__box {
    margin: 8rem 0 5rem 0;

    @include breakpoint-down(medium) {
      margin: 1rem;
    }

    &-circle {
      border: 2px solid black;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      margin: auto;
      @include breakpoint-down(medium) {
        margin: 1rem auto;
      }
      &-p {
        margin: 4.5rem 0rem;
      }
    }
  }
  &__img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
    object-position: 50% 50%;
    filter: drop-shadow(1px 5px 40px rgb(85, 83, 83)) saturate(160%);
    top: 124vh;
    width: 59vw;
    border-radius: 15px;

    @include breakpoint-down(large) {
      top: 143.5vh;
      width: 80vw;
    }

    @include breakpoint-down(medium) {
      width: 70vw;
      top: 161.5vh;
    }
    @include breakpoint-down(small) {
      top: 317vh;
      width: 100%;
    }

  }

  &__animatedCircle {
    padding: 5rem;
    margin: 5rem;
    @include breakpoint-down(medium) {
      margin: 0;
      padding: 0;
    }
  }
}
