@import '../../styles/variables';
@import '../../styles/globals';

.cases {
  color: $black;
  margin-top: 5rem;

  @include breakpoint-down(small) {
    margin-top: 0;
  }

  &__title {
    margin: 20vh 0;
  }

  &__companyName {
    margin: 2rem 0;
  }

  &__box {
    border-radius: 15px;
    transition: all 500ms ease-in-out;
    margin: 15vh 0;
    &:hover {
      box-shadow: 5px 5px 5px 5px #888888;
    }  
      @include breakpoint-down(medium) {
      margin-top: 2rem;
    }
  }

  &__img {
    border-radius: 15px;
    width: 100%;


  }
}