@import '../../styles/variables';
@import '../../styles/mixins';

.blog {
  color: $black;
  margin-top: 8rem;

  &__text {
    margin: 0 14rem;

    @include breakpoint-down(medium) {
      margin: 0;
    }
  }

  &__space {
    margin: 9rem 0;

    @include breakpoint-down(medium) {
      margin: 5rem 0;
    }
  }
  &__img {
    margin: 5rem 0;
    width: 100%;
    
    &-big {
      width: 100%;
      height: 50vh;

      @include breakpoint-down(medium) {
        height: auto;
      }

    }

    &-float {
      float: left;
      margin: 2rem;
    }
  }

  &__galleryimg {
    height: 200px;
    max-width: 280px;
  }
}
