.blog {
  .container {
    padding: 0 15px;
  }

  .center-header__title {
    font-size: 2.5rem;
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .blog__text {
    text-align: justify;
    margin-bottom: 20px;

    p {
      font-size: 16px;
      color: #333;
    }

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .blog__tags {
      font-size: 14px;
      color: #007bff;
      margin-bottom: 10px;

      span {
        margin-right: 10px;
        font-weight: 500;
      }
    }

    .blog__img,
    .blog__img-big,
    .blog__img-second {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: 20px;
    }

    .blog__video {
      width: 100%;
      margin-bottom: 20px;

      video {
        width: 100%;
        height: auto;
        max-height: 400px;  
        object-fit: contain; 
      }
    }

    .blog__description,
    .blog__conclusion {
      font-size: 16px;
      color: #555;
      margin-top: 20px;
    }
  }

  .blogPosts__box {
    margin-top: 40px;
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    .blogDemo__box {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      margin-bottom: 20px;
    }

    .blogDemo__content {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px;
    }

    .blogDemo__imgbox {
      flex: 1;
      height: 250px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 250px;
      }
    }

    .blogDemo__details {
      flex: 2;
      padding: 15px;

      .blogDemo__tags {
        font-size: 14px;
        color: #007bff;
        margin-bottom: 10px;
      }

      .blogDemo__title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: black;
      }

      .blogDemo__author {
        font-size: 14px;
        color: #555;
        margin-bottom: 10px;
      }

      .blogDemo__introduction {
        font-size: 14px;
        color: #333;
        margin-bottom: 20px;
      }

      .text-end a {
        font-size: 14px;
        color: #007bff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .center-header__title {
      font-size: 2rem;
    }

    .blog__text {
      font-size: 14px;
    }

    .blogDemo__box {
      .blogDemo__content {
        flex-direction: column;
      }

      .blogDemo__imgbox {
        height: 200px;
      }

      .blogDemo__details {
        padding: 10px;

        .blogDemo__title {
          font-size: 1.4rem;
        }

        .blogDemo__author {
          font-size: 13px;
        }

        .blogDemo__introduction {
          font-size: 13px;
        }

        .text-end a {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .center-header__title {
      font-size: 1.6rem;
    }

    .blog__text {
      font-size: 12px;
    }

    .blogDemo__box {
      .blogDemo__imgbox {
        height: 180px;
      }

      .blogDemo__details {
        .blogDemo__title {
          font-size: 1.2rem;
        }

        .blogDemo__author {
          font-size: 12px;
        }

        .blogDemo__introduction {
          font-size: 12px;
        }
      }
    }
  }
}
