@import './variables';
@import './mixins';

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-size: $font-med;
  font-weight: 300;
  color: $light-gray;
  line-height: 1.3;
  overflow-x:hidden;

  @include breakpoint-up(small) {
    font-size: $font-med;
  }

  &.noscroll {
    overflow: hidden;
  }
}

.link, .link:visited, .link:hover {
  text-decoration: none;
}

.blue {
  color: $blue-jeans;
}

// Flexbox
.flex {
  display: flex;

  &-jc-sb {
    justify-content: space-between;
  }

  &-jc-c {
    justify-content: center;
  }

  &-ai-c {
    align-items: center;
  }
}

// Headers 
.center-header {
  color: $black;

  @include breakpoint-down(medium) {
    font-size: 2rem;
    padding: 1rem;
  }

  @include breakpoint-up(large) {
    font-size: 3.5rem;
    font-weight: $font-thick;
  }

  &__title {
    margin: 8rem 0 4rem 0;

    @include breakpoint-down(medium) {
      margin: 3rem 0 0 0;
    }
  }
}

// Buttons
button,
.button {
  padding: 0.875rem 2.1875rem;
  background: linear-gradient(to left, $blue-jeans, $blue-logo);
  border-radius: 30px;
  border: 0;
  cursor: pointer;
  color: $white;
  font-weight: 400;
  text-decoration: none;
  transition: all 300ms ease-in-out;

  &:hover {
    opacity: 0.85;
    color: $light-gray;
  }
}

.banner-img {
  width: 100vw;
  height: auto;

  // @include breakpoint-down(small) {
  //   margin-top: 4rem;
  // }

  &__black {
    filter: drop-shadow(1px 10px 40px rgb(0, 0, 0)) saturate(160%);
  }

  &__header {
    position: absolute;
    width: 60vw;
    top: 40vh;
    left: 20vw;
    line-height: 1.5;
    font-size: 4rem;
    font-weight: $font-big;
    color: $white;

    @include breakpoint-down(medium) {
      font-size: 3.5rem;
    }

    @include breakpoint-down(small) {
      font-size:  3rem;
      top: 15vh;
    }

    @include breakpoint-down(mobile) {
      top: 10vh;
      left: 5vw;
      width: 90vw;
      font-size:  2rem;
    }

    &__colorwhite {
      color: white;
    }
  }
}

.b_read {
  max-width: 70ch;
  margin: auto;

  @include breakpoint-down(mobile) {
    margin: auto 1rem;
  }
}

// Visibility
.hide-for-mobile {
  // hide for tablet and mobile
  @include breakpoint-down(small) {
    display: none;
  }
}

.hide-for-desktop {
  // hide for desktop viewport widths
  @include breakpoint-up(large) {
    display: none;
  }
}
