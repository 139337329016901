@import '/src/styles/variables';
@import '/src/styles/_mixins.scss';

.nsocComponent {
  font-size: $font-xl;
  position: relative;
  z-index: 1;

  &__white {
    color: #FBFCF6;
  }

  &__title {
    width: 100%;
    font-size: 4rem;
    position: absolute;
    top: 55vh;
    background: rgba($color: #000000, $alpha: 0.5);

    @include breakpoint-down(medium) {
      top: 50vh;
      font-size: 3.2rem;
    }
    @include breakpoint-down(small) {
      top: 20vh;
      font-size: 2.2rem;
    }
    @include breakpoint-down(mobile) {
      top: 18vh;
      font-size: 1.1rem;
    }
  }
}