@import '../../styles/variables';
@import '../../styles/mixins';

.readmore {
  &__button {
    text-decoration: none;
    color: $blue-logo;
    font-size: $font-sm;
    transition: all 500ms ease-in-out;
    margin: 0px 0rem;

    &:hover {
      color: $blue-jeans;
      margin: 0px 1.5rem;
    }

    @include breakpoint-down(medium) {
      font-size: $font-ssm;
    }
  }
}
