@import '../../styles/variables';
@import '/src/styles/mixins';

.header {
  .white {
    color: $white;
  }
  
  .bg-white {
    background-color: $white;
    transition: all 300ms ease-in-out;
  }

  //Hamburger Open Styles
  &.open {
    .header__toggle {
      > span:first-child {
        transform: rotate(45deg);
      }
  
      > span:nth-child(2) {
        opacity: 0;
      }
  
      > span:last-child {
        transform: rotate(-45deg);
      }
    }
  }

  .overlay {
    opacity: 0;
    position: fixed;
    top: 4rem;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: linear-gradient($blue-logo, transparent);
  }

  nav {
    position: relative;
  }

  &__logo {
    img {
      width: 150px;
      @include breakpoint-down(small) {
        width: 100px;
      }
    }
  }

  &__toggle {
    // Mobile Menu
    > span {
      display: block;
      width: 26px;
      height: 2px;
      background-color: $blue-logo;
      transition: all 300ms ease-in-out;
      transform-origin: 1.7px 1px;

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }

  &__menu {
    position: absolute;
    width: calc(100% - 3rem);
    left: 50%;
    transform: translateX(-50%);
    background: $white;
    margin-top: 1.5rem;
    padding: 1.625rem;
    border-radius: 5px;

    &-links {
      display: block;
      padding: 0.625rem;
      color: $blue-logo;
      text-align: center;
      font-weight: $font-thick;
    }
  }

  &__links {
    &-link {
      font-family: 'Poppins', sans-serif;
      font-size: $font-med;
      position: relative;
      color: $blue-logo;
      font-weight: $font-thick;
      transition: all 300ms ease-in-out;
      
      @include breakpoint-down(small) {
        font-weight: $font-slim;
      }

      &:not(:last-child) {
        margin-right: 32px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 3px;
        left: 0;
        right: 0;
        bottom: -10px;
        background: linear-gradient(to left, $light-gray, $blue-logo);
        width: 0%;
        transition: all 300ms ease-in-out;
      }

      
      &:hover {
        color: $blue-jeans;

        &:before {
          width: 100%;
        }
      }
    }
  }
}



