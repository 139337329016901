@import '../../styles/mixins';
@import '../../styles/variables';

.CookieConsent {
  @include breakpoint-down(medium) {
    justify-content: center !important;
  }
  &__button {
    @include breakpoint-down(medium) {
      width: 50vw;
      margin: auto;
    }
  }
}

.modal-dialog {
  color: $black;
  font-size: $font-ssm;
}

.card-body {
  font-size: $font-sm;
}

.noDisplay {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.check {
  font-size: $font-med;
  margin-top: 0.6rem;
}