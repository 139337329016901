@import '../../styles/variables';
@import '../../styles/mixins';

.searchBox {
  position: absolute;
  z-index: 1;
  text-align: center;
  
  &__Inputs {
    // top: 50px;
    position: relative;
    display: flex;
    margin: 5rem 0 2rem 0;
    left: 28vw;
    @include breakpoint-down(large) {
      left: 20vw;
    }
    @include breakpoint-down(medium) {
      left: 13.5vw;
    }
    
    &-input {
      background-color: white;
      border-radius: 5px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 18px;
      padding: 15px;
      width: 60vw;

      &:focus {
        outline: none;
      }

      @include breakpoint-up(large) {
        width: 800px;
      }
    }
  }

  &__Icon {
    width: 50px;
    background-color: white;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: black;
    display: grid;
    place-items: center;
    border: 1px solid lightgrey;
  }

  &__dataResult {
    position: absolute;
    margin-top: 5px;
    top: 20vh;
    left: 30vw;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    width: 40vw;

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      display: block;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      color: black;
      padding: 1rem;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 5px;
        color: blue;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }

  &__clearBtn {
    cursor: pointer;
  }
}

