@import '../../styles/variables';
@import '../../styles/mixins';

.ourServices {
  color: $black;
  padding: 5rem;

  @include breakpoint-down(medium) {
    padding: 0;
  }

  &__bgColor {
    background: hsla(205, 95%, 45%, 0.1);
    border-radius: 10px;
  }

  &__container {
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    color: $black;
    
    &:hover {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
      color: $white;
      // cursor: pointer;
    }
  }

  &__1{
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 1)
        ),
        url('https://i.imgur.com/nc1zEeK.jpg');
        background-size: cover;
        background-blend-mode: overlay;
        color: $white;
        border-radius: 15px;
      object-position: 50% 50%;
    }
  }

  &__2{
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(
        rgba(255, 255, 255, 0.65),
        rgba(255, 255, 255, 0.65)
        ),
        url('https://i.imgur.com/NT1vi1E.jpg');
        background-size: cover;
        background-blend-mode: overlay;
        color: $white;
        border-radius: 15px;
      object-position: 50% 50%;
    }
  }

  &__3{
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      background: linear-gradient(
        rgba(255, 255, 255, 0.35),
        rgba(255, 255, 255, 0.35)
        ),
        url('https://imgur.com/8hwiiHu.jpg');
        background-size: cover;
        background-blend-mode: overlay;
        color: $white;
        border-radius: 15px;
      object-position: 50% 50%;
    }
  }
  
  &-anchor {
    color: $black;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: $white;
      transition: all 0.3s ease-in-out;
    }
  }
  
  &__color {
    color: $blue-logo;

    &:hover {
      color: $white;
    }

  }
}
